import { getDB, setDB } from '!/db'
import { SavedEateryModel } from '!/models'
import { createEffect, createRoot } from 'solid-js'
import { createStore } from 'solid-js/store'
import { USER_SAVE } from './dbLabels'

const [saved, setSaved] = createStore<SavedEateryModel[]>([])

async function getSaved(): Promise<SavedEateryModel[]> {
    return getDB(USER_SAVE)
        .then((result: SavedEateryModel[]) => (result ? [...result] : []))
        .catch(() => [])
}

getSaved().then(setSaved)

createRoot(() => {
    createEffect(() => {
        setDB(USER_SAVE, [...saved])
    })
})

export { saved, setSaved }
