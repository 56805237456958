import {
    EateryModel,
    GeneModel,
    OpeningHours,
    SessionInfoModel,
} from '!/models'
import { LngLat } from 'maplibre-gl'
import { UAParser } from 'ua-parser-js'

const parser = new UAParser(navigator.userAgent)

export function record_url(gene: GeneModel): string {
    if (!gene) return ''

    if (gene.startsWith('https')) return gene

    return `/record/${gene}`
}

export const cubicBezier = (
    t: number,
    p1: number,
    p2: number,
    p3: number,
    p4: number
) => {
    return (
        3 * p1 * (1 - t) * (1 - t) * t +
        3 * p2 * (1 - t) * t * t +
        p4 * t * t * t
    )
}

export type getDistanceType = {
    value: number
    unit: 'متر' | 'کیلومتر'
}
export function getDistance(
    lat1: number,
    lng1: number,
    lat2: number,
    lng2: number,
    format: boolean = false
): getDistanceType {
    const eatery = new LngLat(lat1, lng1)
    const user = new LngLat(lat2, lng2)
    const distance = Math.floor(user.distanceTo(eatery))

    if (format) {
        return distance > 1000
            ? { value: Math.floor(distance / 1000), unit: 'کیلومتر' }
            : { value: distance, unit: 'متر' }
    }

    return {
        unit: 'کیلومتر',
        value: distance / 1000,
    }
}

const OS = ['unknown', 'linux', 'windows', 'mac', 'ios', 'android']
const BROWSERS = ['unknown', 'chrome', 'firefox', 'safari', 'opera', 'edge']
const DEVICES = ['unknown', 'desktop', 'mobile', 'embeded']

export function convert_version(version: string | number) {
    if (!version) return 0

    if (typeof version == 'string') {
        version = parseInt(version.split('.')[0]) || 1
    }

    return Math.max(Math.min(version, 1 << 15), 1)
}

export function get_session_info(): SessionInfoModel {
    let { os, device, browser } = parser.getResult()

    let osn = (os.name || '').toLowerCase()
    let brn = (browser.name || '').toLowerCase()
    let dvn = (device.type || '').toLowerCase()

    return {
        client: 2,
        os: Math.max(OS.indexOf(osn), 0),
        browser: Math.max(BROWSERS.indexOf(brn), 0),
        device: Math.max(DEVICES.indexOf(dvn), 0),
        os_version: convert_version(os.version),
        client_version: 1,
        browser_version: convert_version(browser.version),
    }
}

export const formatOhTime = (time: number): string | null => {
    if (time === 0) return null
    const totalMinutes = (time - 1) * 15
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
    )}`
}

type starsType = [number, number, number, number, number]
export function getAverageRating(stars: starsType) {
    const totalReviews = stars.reduce((sum, count) => sum + count, 0) // Sum of all reviews
    if (totalReviews === 0) return 0 // Avoid division by zero

    const weightedSum = stars.reduce(
        (sum, count, index) => sum + count * (index + 1),
        0
    )

    return Math.min(5, Math.ceil(weightedSum / totalReviews))
}

export function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor
    const isTouchDevice =
        'ontouchstart' in window || navigator.maxTouchPoints > 0
    const isSmallScreen = window.matchMedia('(max-width: 768px)').matches

    return (
        /android|iphone|ipad|ipod|opera mini|iemobile|mobile/i.test(
            userAgent
        ) ||
        isTouchDevice ||
        isSmallScreen
    )
}

export function isOpenNow(openingHours: OpeningHours): boolean {
    const now = new Date()
    const currentDay = now.getDay()
    const currentHour = now.getHours()
    const currentMinutes = now.getMinutes()

    // Calculate the current 15-minute slot
    const currentSlot = Math.floor(currentHour * 4 + currentMinutes / 15)

    // Get today's opening intervals
    const todayIntervals = openingHours[currentDay]

    // Check if current slot falls in any interval
    for (const interval of todayIntervals) {
        if (interval.open <= currentSlot && currentSlot < interval.close) {
            return true
        }
    }

    return false
}

export function getOpenUntil(openingHours: OpeningHours): string {
    const now = new Date()
    const currentDay = now.getDay()
    const currentHour = now.getHours()
    const currentMinutes = now.getMinutes()

    // Get today's opening hours
    const todaysHours = openingHours[currentDay]

    const currentSlot = Math.floor(currentHour * 4 + currentMinutes / 15)

    // Find the current active period
    const currentPeriod = todaysHours.find(
        period => period.open <= currentSlot && period.close > currentSlot
    )

    if (currentPeriod) {
        return formatOhTime(currentPeriod.close)
    }

    return '-- : --'
}

export function throttle<T extends (...args: any[]) => void>(
    fn: T,
    delay: number
): T {
    let lastCall = 0
    return ((...args: any[]) => {
        const now = Date.now()
        if (now - lastCall >= delay) {
            lastCall = now
            fn(...args)
        }
    }) as T
}

export function debounce<T extends (...args: any[]) => void>(
    fn: T,
    delay: number
): T {
    let timeoutId: ReturnType<typeof setTimeout> | null = null

    return ((...args: any[]) => {
        if (timeoutId !== null) {
            clearTimeout(timeoutId)
        }
        timeoutId = setTimeout(() => {
            fn(...args)
        }, delay)
    }) as T
}
