import { getDB, setDB } from '!/db'
import { createEffect, createRoot, createSignal } from 'solid-js'
import { USER_THEME } from './dbLabels'

const ALLOWED_THEMES = ['light', 'dark'] as const
type Theme = (typeof ALLOWED_THEMES)[number]

export const [theme, setTheme] = createSignal<Theme>('light')

async function defaultTheme(): Promise<Theme> {
    return getDB(USER_THEME)
        .then((dbTheme: unknown) => {
            const storedTheme = dbTheme as Theme
            if (ALLOWED_THEMES.includes(storedTheme)) {
                return storedTheme
            }

            throw new Error('Invalid theme')
        })
        .catch(() => {
            if (
                matchMedia &&
                matchMedia('(prefers-color-scheme: dark)').matches
            ) {
                return 'dark' as Theme
            }
            return 'light' as Theme
        })
}

// Set the initial theme using promise chaining
defaultTheme().then(setTheme)

createRoot(() => {
    createEffect(() => {
        setDB(USER_THEME, theme())
        document.documentElement.setAttribute('data-theme', theme())
    })
})
