import { EateryModel, GeneModel } from '!/models'
import { httpx } from '!/shared'
import { createStore } from 'solid-js/store'

type EateryMapModel = {
    show: boolean
    fullscreen: boolean
    loading: boolean
    eatery: EateryModel | null
}

const [eateryMap, setEateryMap] = createStore<EateryMapModel>({
    show: false,
    fullscreen: false,
    loading: true,
    eatery: null,
})

function fetch_eatery(gene: GeneModel, guest?: boolean) {
    if (!gene) return

    if (gene === eateryMap.eatery?.gene) {
        return setEateryMap({ show: true, loading: false })
    }

    setEateryMap({ show: true, loading: true, eatery: null })

    if (guest) {
        httpx({
            method: 'GET',
            url: `/api/guest/eateries/${gene}/`,
            onLoad(x) {
                setEateryMap({ loading: false })

                if (x.status !== 200) return

                setEateryMap({
                    eatery: x.response,
                })
            },
            onError() {
                setEateryMap({ loading: false })
            },
        })

        return
    }

    httpx({
        method: 'GET',
        url: `/api/eateries/${gene}/`,
        onLoad(x) {
            setEateryMap({ loading: false })

            if (x.status !== 200) return

            setEateryMap({
                eatery: x.response,
            })
        },
        onError() {
            setEateryMap({ loading: false })
        },
    })
}

export { type EateryMapModel, eateryMap, setEateryMap, fetch_eatery }
