import { getDB, setDB } from '!/db'
import { UserModel } from '!/models'
import { httpx } from '!/shared'
import { createEffect, createRoot } from 'solid-js'
import { createStore } from 'solid-js/store'
import { USER_COOKIE_TOKEN, USER_GUEST } from './dbLabels'

type selfType = {
    loading: boolean
    fetch: boolean
    user: UserModel
    guest: boolean
    login: boolean
}

const [self, setSelf] = createStore<selfType>({
    loading: true,
    fetch: false,
    user: null,
    guest: false,
    login: false,
})

// Initialize user and handle loading state
;(async () => {
    let is_guest = await getDB(USER_GUEST)

    if (is_guest === 'true') {
        return setSelf({
            fetch: false,
            guest: true,
            loading: false,
            user: null,
        })
    }

    try {
        const user = await get_default()
        setSelf({
            user,
            loading: false,
        })
    } catch {
        setSelf({
            user: null,
            loading: false,
            guest: true,
        })
        setDB(USER_GUEST, 'true')
    }
})()

async function get_default(): Promise<UserModel> {
    let db_cookie = await getDB(USER_COOKIE_TOKEN)

    return new Promise((res, rej) => {
        httpx({
            method: 'GET',
            url: '/api/user/',
            show_messages: false,
            headers: {
                ...(db_cookie && { authorization: db_cookie }),
            },
            onLoad(x) {
                setSelf({ loading: false })

                if (x.status !== 200) {
                    return rej()
                }

                let { gene, session_index, sessions } = x.response

                setDB(
                    USER_COOKIE_TOKEN,
                    `user ${gene}:${session_index}:${sessions[session_index].token}`
                )

                res(x.response)
            },
            onError() {
                setSelf({ loading: false })
                return rej()
            },
        })
    })
}
async function fetch_user() {
    let db_cookie = await getDB(USER_COOKIE_TOKEN)

    httpx({
        method: 'GET',
        url: '/api/user/',
        show_messages: false,
        headers: {
            ...(db_cookie && { authorization: db_cookie }),
        },
        onLoad(x) {
            if (x.status !== 200)
                return setSelf({
                    fetch: false,
                    user: null,
                })

            let { gene, session_index, sessions } = x.response

            setDB(
                USER_COOKIE_TOKEN,
                `user ${gene}:${session_index}:${sessions[session_index].token}`
            )

            setSelf({
                fetch: false,
                user: x.response,
            })
        },
        onError() {
            return setSelf({
                fetch: false,
                user: null,
            })
        },
    })
}

function logout_user(): Promise<void> {
    setDB(USER_COOKIE_TOKEN, {})
    setDB(USER_GUEST, 'true')
    setSelf(null)

    return new Promise((res, rej) => {
        httpx({
            url: '/api/user/logout/',
            method: 'POST',
            onLoad(x) {
                if (x.status !== 200) rej()

                res()
            },
        })
    })
}

createRoot(() => {
    createEffect(() => {
        if (!self.fetch) return

        fetch_user()
    })
})

export { self, setSelf, fetch_user, logout_user }
