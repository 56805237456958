import { addAlert } from '!/comps'
import { createRoot, onCleanup } from 'solid-js'
import { createStore } from 'solid-js/store'

type locationType = {
    userLat: number
    userLng: number
    watchId: number | null
}
const [location, setLocation] = createStore<locationType>({
    userLat: 0,
    userLng: 0,
    watchId: null,
})

async function startLiveLocationTracking(msg: boolean = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        if (location.watchId) {
            resolve()
            return
        }

        if (!navigator.geolocation) {
            if (msg) {
                addAlert({
                    type: 'error',
                    timeout: 3,
                    subject: 'خطا موقعیت!',
                    content: '.موقعیت مکانی خود را فعال کنید',
                })
            }
            reject()
            return
        }

        // Start live tracking
        const watchId = navigator.geolocation.watchPosition(
            position => {
                const { latitude, longitude } = position.coords

                setLocation({
                    userLat: latitude,
                    userLng: longitude,
                    watchId: watchId,
                })

                resolve()
            },
            error => {
                if (msg) {
                    addAlert({
                        type: 'error',
                        timeout: 3,
                        subject: 'مشکل در دریافت موقعیت',
                        content: '',
                    })
                }

                setLocation({
                    ...location,
                    watchId: null, // Clear watchId on failure
                })

                reject(error)
            },
            { enableHighAccuracy: true }
        )

        setLocation({ ...location, watchId })
    })
}

const stopLiveLocationTracking = () => {
    if (location.watchId !== null) {
        navigator.geolocation.clearWatch(location.watchId)
        setLocation({ watchId: null })
    }
}

createRoot(() => {
    onCleanup(() => {
        stopLiveLocationTracking()
    })
})

export { startLiveLocationTracking, location, setLocation }
